import { default as financingNrXGNKqeL2Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as indexZSOs2NnPX6Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyW4rFO8noQwMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamid1dIpUfjnMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45areay6PBfeC5TuMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areatS21FwEW54Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45areaWg1TbgKfX8Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaZh6bjM08lqMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaxpaSDN7CAZMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areaUJiCyPjFOpMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45areaou6SHEL192Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areahaufaD6x5jMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93fuTFMo2AebMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexqHYfmEsojMMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexrFGLkS1RsRMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93rSl9egEMLsMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexBqDLnMVUWwMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadowsr9C12S4uzbMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estatesvhQMazo4sgMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templatecyjuNwxj7FMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as indexvirwfuXvyKMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45questionCiSyBjamIVMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitieslMmw4LnhpWMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqgvg46PExSuMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcespZuDItkDe1Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexePSrcDRWtEMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations76YQM4WAs7Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryIhR6w4kH7sMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offers6QITBXZoR6Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexHMUwjkqsDDMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indext74Y0HX8x5Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexyE0X9OzTzXMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index9QV7DcIzpPMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as indexEawAuJkWwHMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionvM2wP6MccHMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_937t2FnWua1OMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexMbbMA64FkoMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexQtXf36iTLiMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93ibPMeHC1dbMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as indext4L9b4zEavMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favoritesfVG70zPUEVMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchu9ZudMuq6JMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communitiesSQF0rrYHHVMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as easyhousema84Qzv8C8Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionzd4kzPKis8Meta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageGBx7lOmCBOMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexyAJhQ7RXOUMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsAUjkUpDzqwMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as index8BABmwWHtCMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandboxYnjIUvWTsqMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preservebheUPRSH8FMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creekRmfyx3v1EuMeta } from "/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93rSl9egEMLsMeta || {},
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/coming-soon/template.vue").then(m => m.default || m)
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    meta: indexvirwfuXvyKMeta || {},
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexyE0X9OzTzXMeta || {},
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-easyhouse",
    path: "/our-homes/home-collections/easyhouse",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-limitededition",
    path: "/our-homes/home-collections/limitededition",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-wee-cottage",
    path: "/our-homes/home-collections/wee-cottage",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/codebuild/output/src57376064/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]